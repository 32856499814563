<template>
    <div class="wrapper">

        <section class="encabezado -section" data="plan-estrategico">

            <h2 class="title -white">Plan Operativo de Gobierno</h2>

            <h3 class="subtitle">
                ¿Cómo vamos a conseguirlo?
            </h3>

        </section>

        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link :to="$route.fullPath">Compromisos de Gobierno</router-link>
                <router-link :to="$route.fullPath">Plan Operativo de Gobierno ¿Cómo vamos a conseguirlo?</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>

        <section class="cuadro-resumen-banner -section">
            <div class="container">
                <nav class="row-center">
                    <router-link :to="$t('enlaces.compromisos')">¿Cómo queremos que sea Madrid?</router-link>
                    <router-link :to="$t('enlaces.planoperativo')">¿Cómo vamos a conseguirlo?</router-link>
                    <router-link :to="$t('enlaces.indicecumplimiento')">Índice de cumplimiento</router-link>
                </nav>

                <article class="cuadro row-between">


                    <template>
                        <div class="texto">
                            <h2 class="title -left"><strong>Plan Operativo de Gobierno</strong> ¿Cómo vamos a
                                conseguirlo?
                            </h2>
                            <p>Definimos para cada objetivo estratégico las estrategias/proyectos y las actuaciones a
                                través de las cuales se van a operativizar, se van a hacer realidad.
                            </p>
                        </div>


                        <div class="imagen row-end">

                            <img src="/img/imagen-ayto.jpg" title="Imagen del Ayuntamiento" alt="Imagen del Ayuntamiento">
                        </div>

                    </template>

                </article>

            </div>
        </section>




        <section class="plan-estrategico-banner -section">
            <div class="container">
                <h2 class="title -white -large">El Plan Operativo de Gobierno 2019 - 2023 en cifras:</h2>
                <div class="stats-progress-bar row-center">

                    <div class="stats row-center">
                        <article class="stat -big">
                            <strong v-if="homedata">
                                <count-to :endVal="homedata.planes" :separator="'.'"></count-to>
                            </strong>
                            <span>Planes transversales</span>
                        </article>

                        <article class="stat -big">
                            <strong v-if="homedata">
                                <count-to :endVal="homedata.proyectos" :separator="'.'"></count-to>
                            </strong>
                            <span>Estrategias/ Proyectos</span>
                        </article>

                        <article class="stat -big">
                            <strong v-if="homedata">
                                <count-to :endVal="homedata.actuaciones" :separator="'.'"></count-to>
                            </strong>
                            <span>Actuaciones</span>
                        </article>

                    </div>


                    <!-- <progress-bar :title="'Actuaciones terminadas'"
                        :percent="homedata.actuaciones_terminadas*100/homedata.actuaciones" :color="'green'">
                    </progress-bar> -->
                    <progress-bar :title="'Actuaciones terminadas y en ejecución'" :percent="terminadasyenejecucion"
                        :color="'green'">
                    </progress-bar>

                </div>

            </div>
        </section>

        <section class="title-container -section">
            <div class="container">
                <h2 class="title -left -primary">Conseguiremos que Madrid sea:</h2>
            </div>
        </section>

        <cifras-eje :key="key" :data="data" v-for="(data, key) in dominios.ejes"></cifras-eje>




        <banner-ayudamos :data="['que-es-pog', 'plan-estrategico', 'observatorio-ciudad']"></banner-ayudamos>

    </div>
</template>


<script>

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex'

import cifraseje from '@/components/parts/cifras-eje'

import router from '@/router';

export default {
    name: 'plan-operativo',
    mounted() {
        this.requestHomeData();
        this.requestDominios();
    },
    computed: {
        ...mapGetters({
            dominios: 'getDominios',
            homedata: 'getHomedata'
        }),
        noiniciadas() {
            return Number(this.homedata.actuaciones_futuras) + Number(this.homedata.actuaciones_planificadas) + Number(this.homedata.actuaciones_retrasadas);
        },
        terminadasyenejecucion() {
            let noiniciadas = Number(this.homedata.actuaciones_futuras) + Number(this.homedata.actuaciones_planificadas) + Number(this.homedata.actuaciones_retrasadas);
            let terminadas = Number(this.homedata.actuaciones) - Number(noiniciadas);
            let porcentaje = (terminadas * 100) / Number(this.homedata.actuaciones);

            return Number(porcentaje);
            //return Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas) 
        },

    },
    methods: {
        ...mapActions(['requestHomeData', 'requestDominios']),
        back() {
            router.go(-1);
        }
    },
    components: {
        'cifras-eje': cifraseje
    }
}
</script>