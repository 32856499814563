<template>
    <section class="eje-stats -section">
        <div class="container">
            <header>
                <h2>
                    <span>
                        {{ data.nombre }}
                    </span>
                </h2>
            </header>

            <main class="eje-stats-container row-center">
                <div class="stats row-center">

                    <article class="stat -big">
                        <strong>
                            <count-to :endVal="data.objetivos" :separator="'.'"></count-to>
                        </strong>
                        <span>Objetivos estratégicos</span>
                    </article>
                    <article class="stat -big">
                        <strong>
                            <count-to :endVal="data.proyectos" :separator="'.'"></count-to>
                        </strong>
                        <span>Estrategias/ Proyectos</span>
                    </article>

                    <article class="stat -big">
                        <strong>
                            <count-to :endVal="data.actuaciones"> :separator="'.'"</count-to>
                        </strong>
                        <span>Actuaciones</span>
                    </article>

                </div>

                <!-- <progress-bar :title="'Actuaciones terminadas'"
                    :percent="data.actuacionesterminadas*100/data.actuaciones" :color="'green'" :primary="true">
                </progress-bar> -->

                <progress-bar :title="'Actuaciones terminadas y en ejecución'" :percent="terminadasyenejecucion"
                    :color="'green'" :primary="true">
                </progress-bar>

                <div class="contenedor-boton">
                    <router-link :to="$t('enlaces.planoperativo') + '/' + data.id" class="btn">Ver Eje en detalle
                    </router-link>
                </div>

            </main>
        </div>
    </section>
</template>

<script>
export default {
    name: 'cifras-eje',
    props: ['data'],
    computed: {
        noiniciadas() {
            return Number(this.data.actuacionesfuturas) + Number(this.data.actuacionespendienteplanificar) + Number(this.data.actuacionesretrasada);
        },
        terminadasyenejecucion() {
            let noiniciadas = Number(this.data.actuacionesfuturas) + Number(this.data.actuacionespendienteplanificar) + Number(this.data.actuacionesretrasada);
            let terminadasyenejecucion = Number(this.data.actuaciones) - Number(noiniciadas);
            let porcentaje = Math.round((terminadasyenejecucion * 100) / Number(this.data.actuaciones));

            return Number(porcentaje);
            //return Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas) 
        },
    },
}

</script>